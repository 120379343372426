import React, { Component } from 'react'
import PersonList from '../components/InterpreterSearch/personList'
import CodeSearch from '../components/InterpreterSearch/CodeList'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import '../components/InterpreterSearch/interpreterSearch.css'
import WithZipcode from '../components/InterpreterSearch/WithZipcode'
import StateCitySearch from '../components/InterpreterSearch/StateCity'
import WithinStateCitycode from '../components/InterpreterSearch/WithinStateCity'
import not from '../images/notfound.png'

const InterpreterResult = () => {
  const callNameSearch = typeof window !== 'undefined' ? sessionStorage.getItem("callNameSearch") : null
  const callCodeSearch = typeof window !== 'undefined' ? sessionStorage.getItem("callCodeSearch") : null
  const callwithipcode = typeof window !== 'undefined' ? sessionStorage.getItem("callwithipcode") : null
  const callStateCitySearch = typeof window !== 'undefined' ? sessionStorage.getItem("callStateCitySearch") : null
  const callWithinStateCitySearch = typeof window !== 'undefined' ? sessionStorage.getItem("callWithinStateCitySearch") : null
  

  // if(callCodeSearch == "true"){
    
  //   return (
  //      <Layout>
  //        <div className="card resultcard" style={{backgroundColor:"#f9f9ff"}}>
  //         <Link to="/SearchInterpreter">
  //           {' '}
  //           <span style={{ fontSize: '25px' }}>←</span> Back to Search Interpreter page
  //         </Link>
  //         <p style={{float:"left"}}>Last updated on: 28-01-2022</p>
  //         <CodeSearch />
  //         </div>
  //       </Layout>)
  // }

  if(callNameSearch == "true"){
    return (
      <Layout>
        <div className="card resultcard" style={{backgroundColor:"#f9f9ff"}}>
         <Link to="/SearchInterpreter">
           {' '}
           <span style={{ fontSize: '25px' }}>←</span> Back to Search Interpreter page
         </Link>
         <p style={{textAlign:"right"}}><b>Last updated on: 28-01-2022</b></p>
         <PersonList />
         </div>
       </Layout>)
  }

  if(callwithipcode == "true"){
    return (
      <Layout>
        <div className="card resultcard" style={{backgroundColor:"#f9f9ff"}}>
         <Link to="/SearchInterpreter">
           {' '}
           <span style={{ fontSize: '25px' }}>←</span> Back to Search Interpreter page
         </Link>
         <p style={{textAlign:"right"}}><b>Last updated on: 28-01-2022</b></p>
         <WithZipcode />
         </div>
       </Layout>)
  }
  // if(callStateCitySearch == "true"){
  //   return (
  //     <Layout>
  //       <div className="card resultcard" style={{backgroundColor:"#f9f9ff"}}>
  //        <Link to="/SearchInterpreter">
  //          {' '}
  //          <span style={{ fontSize: '25px' }}>←</span> Back to Search Interpreter page
  //        </Link>
  //        <p style={{textAlign:"right"}}><b>Last updated on: 28-01-2022</b></p>
  //        <StateCitySearch />
  //        </div>
  //      </Layout>)
  // }
  if(callWithinStateCitySearch == "true"){
    return (
      <Layout>
        <div className="card resultcard" style={{backgroundColor:"#f9f9ff"}}>
         <Link to="/SearchInterpreter">
           {' '}
           <span style={{ fontSize: '25px' }}>←</span> Back to Search Interpreter page
         </Link>
         <p style={{textAlign:"right"}}><b>Last updated on: 28-01-2022</b></p>
         <WithinStateCitycode />
         </div>
       </Layout>)  
  }
  
  return(
    <Layout>
    <Link to="/SearchInterpreter">
    {' '}
    <span style={{ fontSize: '25px' }}>←</span> Back to Interpreter Search Page
    </Link>
    <div class="card interpreterresult_card" style={{backgroundColor:"#f9f9ff"}}>
      <div class="center">
      <img src={not}  style={{ width: '25%', height: '25%' }}/>
      <br/>
      <h1><b>No Result Found</b></h1>
      <br />
      <p style={{fontSize:"20px"}}><b>We're sorry. We were not able to find a match. </b></p>
      <p style={{fontSize:"20px"}}><b>Double check your search for any typos or spelling errors.</b></p>
      <br/>
      <br />
      </div>
    </div>
  </Layout>
)
}
export default InterpreterResult