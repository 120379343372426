import React from "react";
import { AXIOSLINK } from "../../utils/constants";
const axios = require('axios')
import './interpreterSearch.css'
import { isEmpty } from "lodash";
import ReactMarkdown from "react-markdown";
import MDEditor from '@uiw/react-md-editor';
import not from '../../images/notfound.png'
import { IMAGELINK } from "../../utils/constants";


export default class PersonList extends React.Component{
    state ={
        persons : []
    };
    componentDidMount(){
        const FirstNameData = typeof window !== 'undefined' ? sessionStorage.getItem("FIRSTNAME") : null
        const LastNameData = typeof window !== 'undefined' ? sessionStorage.getItem("LASTNAME") : null
        console.log(FirstNameData)
        console.log(LastNameData)
        typeof window !== 'undefined' ?sessionStorage.clear() : null
      
        if(isEmpty(FirstNameData) == false && isEmpty(LastNameData) == false){
          axios
            .get(AXIOSLINK + 'users', {
              params: { FirstName: FirstNameData, LastName: LastNameData , Opt: true},
            })
            .then(res => {
              console.log(res)
              this.setState({ persons: res.data })
            })
        }
        else if(isEmpty(FirstNameData) == false && isEmpty(LastNameData) == true){
          axios
            .get(AXIOSLINK + 'users', {
              params: { FirstName: FirstNameData, Opt: true},
            })
            .then(res => {
              console.log(res)
              this.setState({ persons: res.data })
            })
        }
        else if(isEmpty(FirstNameData) == true && isEmpty(LastNameData) == false){
          axios
            .get(AXIOSLINK + 'users', {
              params: { LastName: LastNameData , Opt: true},
            })
            .then(res => {
              console.log(res)
              this.setState({ persons: res.data })
            })
        }
    }
    render(){
      let number1 =0
      var collectData = [];
      let arrlist = this.state.persons


      arrlist.map(function(element){
        var UserImage = null;
        if(element.UserImage == null){
            UserImage = "/uploads/user_dc04a387ec.png"
        }else{
            UserImage = element.UserImage.url
        }
        var ImageUrl = IMAGELINK + UserImage
        var firstname, lastname, interpreterSearchAddress,interpreterSearchCity,interpreterSearchState,interpreterSearchZipcode,interpreterSearchEmail,
                interpreterSearchPhoneNumber,interpreterSearchBusinessNumber,interpreterSearchVideoNumber,interpreterLicense;

                if(isEmpty(element.FirstName)==true){
                  firstname = null
                }
                else{
                  firstname =element.FirstName
                }
                // ************
                if(isEmpty(element.LastName)==true){
                  lastname = null
                }
                else{
                  lastname =element.LastName
                }
                // ************
                if(isEmpty(element.InterpreterSearchAddress)==true){
                  interpreterSearchAddress = null
                }
                else{
                  interpreterSearchAddress = "Address: "+ element.InterpreterSearchAddress
                }
               // ************
               if(isEmpty(element.InterpreterSearchCity)==true){
                  interpreterSearchCity = null
                }
                else{
                  interpreterSearchCity = "City: "+ element.InterpreterSearchCity
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchState)==true){
                  interpreterSearchState = null
                }
                else{
                  interpreterSearchState = "State: "+ element.InterpreterSearchState
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchZipcode)==true){
                  interpreterSearchZipcode = null
                }
                else{
                  interpreterSearchZipcode = "Zipcode: "+ element.InterpreterSearchZipcode
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchEmail)==true){
                  interpreterSearchEmail = null
                }
                else{
                  interpreterSearchEmail = "Email: "+ element.InterpreterSearchEmail
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchPhoneNumber)==true){
                  interpreterSearchPhoneNumber = null
                }
                else{
                  interpreterSearchPhoneNumber = "Phone Number: "+ element.InterpreterSearchPhoneNumber
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchBusinessNumber)==true){
                  interpreterSearchBusinessNumber = null
                }
                else{
                  interpreterSearchBusinessNumber = "Business Number: "+element.InterpreterSearchBusinessNumber
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchVideoNumber)==true){
                  interpreterSearchVideoNumber = null
                }
                else{
                  interpreterSearchVideoNumber = "Video Number: "+element.InterpreterSearchVideoNumber
                }
                 // ************
                 if(isEmpty(element.LicenseType)==true){
                  interpreterLicense = null
                }
                else{
                  interpreterLicense = "License Type: "+element.LicenseType
                }


                const data ={
                    FirstName:firstname,
                    LastName: lastname,
                    InterpreterSearchAddress:interpreterSearchAddress,
                    InterpreterSearchCity:interpreterSearchCity,
                    InterpreterSearchState:interpreterSearchState,
                    InterpreterSearchZipcode:interpreterSearchZipcode,
                    InterpreterSearchEmail:interpreterSearchEmail,
                    InterpreterSearchPhoneNumber:interpreterSearchPhoneNumber,
                    InterpreterSearchBusinessNumber:interpreterSearchBusinessNumber,
                    InterpreterSearchVideoNumber:interpreterSearchVideoNumber,
                    image : ImageUrl,
                    LicenseType : interpreterLicense,
                }

      collectData[number1] = data;
      number1 = number1 + 1;
 
    })

    
            console.log(collectData)


      if(this.state.persons.length == 0){
        console.log("true")
        return (
          <div class="center">
          <img src={not}  style={{ width: '25%', height: '25%' }}/>
          <br/>
          <h1><b>No Result Found</b></h1>
          <br />
          <p style={{fontSize:"20px"}}><b>We're sorry. We were not able to find a match. </b></p>
          <p style={{fontSize:"20px"}}><b>Double check your search for any typos or spelling errors.</b></p>
          <br/>
          
        </div>
        )
      }
        return (
        
          <div className="container">
            <div class="row">
              {collectData.map(resultDatas => (
                <div class="single_box">
                  <div style={{ padding: '20px' }}>
                    <p className="uppercase center" key={resultDatas.id}><b>{resultDatas.FirstName + " " +resultDatas.LastName}</b></p>
                    
                    <div className="center">
                    <img src={resultDatas.image} className="response " style={{width:"260px",height:"218px",borderRadius:"33%"}}/>
                      </div>                      
                      <br/>
                      <p key={resultDatas.id}>{resultDatas.LicenseType}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchAddress}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchCity}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchState}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchZipcode}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchPhoneNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchBusinessNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchVideoNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchEmail}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
         
        )
    }
}


