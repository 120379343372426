import React from "react";
import { AXIOSLINK } from "../../utils/constants";
const axios = require('axios')
import './interpreterSearch.css'
import { first, isEmpty } from "lodash";
import MDEditor from '@uiw/react-md-editor';
import not from '../../images/notfound.png'
import { IMAGELINK } from "../../utils/constants";

var zipcodes = require('zipcodes');

var getZipcode = null;
export default class WithinStateCitycode extends React.Component{
  
  state ={
      codes : [],
      rad: [],   
  };
  
  componentDidMount(){
      const WithInStateCitySearchData = typeof window !== 'undefined' ? JSON.parse(sessionStorage.getItem("WithInStateCitySearchData")) : null;
      typeof window !== 'undefined' ?sessionStorage.clear() : null

      var findZipcode = zipcodes.lookupByName(WithInStateCitySearchData.city, WithInStateCitySearchData.state);
      console.log(findZipcode)

      getZipcode = findZipcode[0].zip;
      console.log(getZipcode)

    console.log(WithInStateCitySearchData)

    if(isEmpty(WithInStateCitySearchData.within)==false && isEmpty(WithInStateCitySearchData.state)==false && isEmpty(WithInStateCitySearchData.city)==false ){
      var radius = zipcodes.radius(getZipcode, WithInStateCitySearchData.within);
      console.log({radius})
      var length = radius.length;
      console.log(length)
      this.setState({rad : radius})

      axios.get(AXIOSLINK + 'users' , {
        params: { Opt: true},
      })
      .then(res => {
        console.log(res)
        this.setState({ codes: res.data })
      })
     
    }
    
  }
  
  
  render(){
        
        let number1 =0
        var collectData = [];
        let arrlist = this.state.codes
        let radiuslist = this.state.rad

        arrlist.map(function(element){
          console.log(element.Zipcode)  
          radiuslist.forEach((number, index) => {
            if(element.Zipcode == number){
                var dist = zipcodes.distance(getZipcode, number); 
                var UserImage = null;
                if(element.UserImage == null){
                  UserImage = "/uploads/user_dc04a387ec.png"
                }else{
                  UserImage = element.UserImage.url
                }
                var ImageUrl = IMAGELINK + UserImage

                var firstname, lastname, interpreterSearchAddress,interpreterSearchCity,interpreterSearchState,interpreterSearchZipcode,interpreterSearchEmail,
                interpreterSearchPhoneNumber,interpreterSearchBusinessNumber,interpreterSearchVideoNumber,interpreterLicense;

                if(isEmpty(element.FirstName)==true){
                  firstname = null
                }
                else{
                  firstname =element.FirstName
                }
                // ************
                if(isEmpty(element.LastName)==true){
                  lastname = null
                }
                else{
                  lastname =element.LastName
                }
                // ************
                if(isEmpty(element.InterpreterSearchAddress)==true){
                  interpreterSearchAddress = null
                }
                else{
                  interpreterSearchAddress = "Address: "+ element.InterpreterSearchAddress
                }
               // ************
               if(isEmpty(element.InterpreterSearchCity)==true){
                  interpreterSearchCity = null
                }
                else{
                  interpreterSearchCity = "City: "+ element.InterpreterSearchCity
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchState)==true){
                  interpreterSearchState = null
                }
                else{
                  interpreterSearchState = "State: "+ element.InterpreterSearchState
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchZipcode)==true){
                  interpreterSearchZipcode = null
                }
                else{
                  interpreterSearchZipcode = "Zipcode: "+ element.InterpreterSearchZipcode
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchEmail)==true){
                  interpreterSearchEmail = null
                }
                else{
                  interpreterSearchEmail = "Email: "+ element.InterpreterSearchEmail
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchPhoneNumber)==true){
                  interpreterSearchPhoneNumber = null
                }
                else{
                  interpreterSearchPhoneNumber = "Phone Number: "+ element.InterpreterSearchPhoneNumber
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchBusinessNumber)==true){
                  interpreterSearchBusinessNumber = null
                }
                else{
                  interpreterSearchBusinessNumber = "Business Number: "+element.InterpreterSearchBusinessNumber
                }
                 // ************
                 if(isEmpty(element.InterpreterSearchVideoNumber)==true){
                  interpreterSearchVideoNumber = null
                }
                else{
                  interpreterSearchVideoNumber = "Video Number: "+element.InterpreterSearchVideoNumber
                }
                 // ************
                 if(isEmpty(element.LicenseType)==true){
                  interpreterLicense = null
                }
                else{
                  interpreterLicense = "License Type: "+element.LicenseType
                }


                const data ={
                    FirstName:firstname,
                    LastName: lastname,
                    InterpreterSearchAddress:interpreterSearchAddress,
                    InterpreterSearchCity:interpreterSearchCity,
                    InterpreterSearchState:interpreterSearchState,
                    InterpreterSearchZipcode:interpreterSearchZipcode,
                    InterpreterSearchEmail:interpreterSearchEmail,
                    InterpreterSearchPhoneNumber:interpreterSearchPhoneNumber,
                    InterpreterSearchBusinessNumber:interpreterSearchBusinessNumber,
                    InterpreterSearchVideoNumber:interpreterSearchVideoNumber,
                    distance : dist,
                    image : ImageUrl,
                    LicenseType : interpreterLicense,
                }

              collectData[number1] = data;
              number1 = number1 + 1;
            }
        });
          
        })

        // sort by value
        collectData.sort(function (a, b) {
          return a.distance - b.distance;
        });



     

        if(collectData.length == 0){
          console.log("true")
          return (
            <div class="center">
            <img src={not}  style={{ width: '25%', height: '25%' }}/>
            <br/>
            <h1><b>No Result Found</b></h1>
            <br />
            <p style={{fontSize:"20px"}}><b>We're sorry. We were not able to find a match. </b></p>
            <p style={{fontSize:"20px"}}><b>Double check your search for any typos or spelling errors.</b></p>
            <br/>
            
          </div>
          )
        }
        
        console.log(collectData)
        
      return (
        
          <div className="container">
            <div class="row">
              {collectData.map(resultDatas => (
                <div class="single_box">
                  <div style={{ padding: '20px' }}>
                    <p className="uppercase center" key={resultDatas.id}><b>{resultDatas.FirstName + " " +resultDatas.LastName}</b></p>
                     <div className="center">
                     <img src={resultDatas.image} className="response" style={{width:"260px",height:"218px",borderRadius:"33%"}}/>
                      </div>
                      <br/>
                      <p key={resultDatas.id}>{resultDatas.LicenseType}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchAddress}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchCity}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchState}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchZipcode}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchPhoneNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchBusinessNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchVideoNumber}</p>
                      <p  key={resultDatas.id}>{resultDatas.InterpreterSearchEmail}</p>
                      <p  key={resultDatas.id}>Distance: {" "+ resultDatas.distance+"Miles" } </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

      )
  }
}